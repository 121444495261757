import { CardinalOrientation, Walktour } from 'walktour'
import { useEffect, useState } from 'react'

import { Tooltip } from './Tooltip'
import { TooltipWhite } from './TooltipWhite'

import type { WalktourLogic, WalktourProps } from 'walktour'

type CoachMarkProps = WalktourProps & {
  /**
   * Function triggered on close event.
   * Use this callback to toggle the value of isOpen.
   * @returns void
   */
  onClose?: () => void
  /**
   * Function triggered on close event of the last step
   * @returns void
   */
  onFinish?: () => void
  onNext?: () => void
  tooltipConfigs?: Record<string, any>
  tooltipVariant?: 'white' | 'blue'
}

export function CoachMark({
  disableClose = false,
  disablePrev = false,
  isOpen: isOpenFromProp,
  onClose,
  onFinish,
  onNext,
  tooltipConfigs = {},
  tooltipVariant = 'blue',
  ...props
}: CoachMarkProps) {
  const [isOpen, setIsOpen] = useState(isOpenFromProp)
  const TooltipComponent = tooltipVariant === 'white' ? TooltipWhite : Tooltip

  // Sync isOpenFromProp
  useEffect(() => {
    setIsOpen(isOpenFromProp)
  }, [isOpenFromProp])

  // Disable background scroll upon open
  useEffect(() => {
    const htmlEl = document.documentElement

    if (isOpen) {
      htmlEl.style.height = '100vh'
      htmlEl.style.width = '100vw'
      htmlEl.style.overflow = 'hidden'
    } else {
      htmlEl.style.height = ''
      htmlEl.style.width = ''
      htmlEl.style.overflow = ''
    }
  }, [isOpen])

  return (
    <Walktour
      initialStepIndex={0}
      isOpen={isOpen}
      disableClose={disableClose}
      tooltipSeparation={2}
      orientationPreferences={[CardinalOrientation.SOUTH]}
      customCloseFunc={({ stepIndex, allSteps, close }) => {
        const isLastStep = stepIndex === allSteps.length - 1

        if (!disableClose || isLastStep) {
          // Invoke the onClose callback
          if (typeof onClose === 'function') onClose()

          // Invoke the onFinish callback on last step
          if (typeof onFinish === 'function' && isLastStep) onFinish()

          // Close the coachmark
          setIsOpen(false)

          // Invoke the Walktour close function to properly clear listeners
          close()
        }
      }}
      customNextFunc={({ next }) => {
        // Invoke the onNext callback
        if (typeof onNext === 'function') onNext()

        // Invoke the Walktour next function to properly clear listeners
        next()
      }}
      customTooltipRenderer={(tourLogic: WalktourLogic) => (
        <TooltipComponent
          disablePrev={disablePrev}
          {...tourLogic}
          {...tooltipConfigs}
        />
      )}
      {...props}
    />
  )
}
