import Head from 'next/head'

import { Button, type ButtonProps } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text-v1'

import type { WalktourLogic } from 'walktour'

type TooltipWhiteProps = WalktourLogic & {
  disablePrev: boolean
  prevLabel?: string
  nextLabel?: string
  closeLabel?: string
}

export function TooltipWhite({ disablePrev, ...props }: TooltipWhiteProps) {
  const {
    allSteps,
    stepIndex,
    prev,
    next,
    close,
    stepContent: {
      customDescriptionRenderer,
      customTitleRenderer,
      title,
      description,
    },
    prevLabel,
    nextLabel,
    closeLabel,
  } = props

  const isLastStep = stepIndex === allSteps.length - 1

  return (
    <>
      <Head>
        {/* Inject style to remove outline */}
        <style>
          {`#walktour-tooltip-container:focus-visible {
            outline: none !important;
          }`}
        </style>
      </Head>
      <div className="absolute bottom-[2px] flex justify-center w-full px-8 mx-0 text-white">
        <div>
          <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            className="rotate-180"
          >
            <path
              d="M7.57796 1.74961C8.35974 0.485803 10.1979 0.485803 10.9797 1.74961L18.5577 14H0L7.57796 1.74961Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>

      <div className="flex justify-center w-screen mb-4">
        <div className="max-w-xl p-4 mx-4 !text-black bg-white rounded-lg">
          {customTitleRenderer
            ? customTitleRenderer(title, props)
            : title && (
                <Text variant="heading-sm" className="mb-1">
                  {title}
                </Text>
              )}
          {customDescriptionRenderer ? (
            customDescriptionRenderer(description, props)
          ) : (
            <Text variant="body">{description}</Text>
          )}

          <div className="flex justify-end mt-6 space-x-4">
            {!disablePrev && (
              <TooltipButton onClick={prev}>
                {prevLabel ?? 'Kembali'}
              </TooltipButton>
            )}

            {isLastStep ? (
              <TooltipButton
                onClick={() => {
                  close()
                }}
              >
                {closeLabel ?? 'Selesai'}
              </TooltipButton>
            ) : (
              <TooltipButton onClick={next}>
                {nextLabel ?? 'Lanjut'}
              </TooltipButton>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface ITooltipButtonProps extends ButtonProps {
  children: React.ReactNode
  onClick: () => void
}
function TooltipButton({ children, ...props }: ITooltipButtonProps) {
  return (
    <Button size="sm" color="black" {...props}>
      {children}
    </Button>
  )
}
