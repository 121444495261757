import { hasCookie, getCookie, setCookie } from 'cookies-next'

import { IS_CONVERSATION_WITH_AI_ENABLED } from 'configs/toggle'
import { mutateExperimentParticipation } from 'utils/experiment'
import { EXPERIMENT_KEY } from 'app/ConversationPage/configs'
import { useAuth } from 'utils/auth'

const useExperiment = (deviceId) => {
  const { session } = useAuth()
  const userId = session?.user?.id ?? ''

  const getExperimentStatus = async () => {
    // returns "false" if ENV Toggle disabled
    if (!IS_CONVERSATION_WITH_AI_ENABLED) {
      return false
    }

    try {
      let experimentData = undefined
      const cookieOptions = {}
      const experimentCookieKey = `${EXPERIMENT_KEY}_${userId}_${deviceId}_cache`

      if (hasCookie(experimentCookieKey, cookieOptions)) {
        // Get experiment data from Cookies
        const cookieData = getCookie(
          experimentCookieKey,
          cookieOptions
        ) as string
        experimentData = JSON.parse(cookieData)
      } else {
        // Participate to experiment
        experimentData = await mutateExperimentParticipation(
          EXPERIMENT_KEY,
          deviceId,
          {
            userId,
          }
        )

        // Set experiment data to Cookies
        setCookie(experimentCookieKey, JSON.stringify(experimentData), {
          ...cookieOptions,
          maxAge: 60 * 60, // 1 hour
        })
      }

      // Check if user is in variant
      const isExperimentVariantEnabled =
        experimentData?.experiment?.treatment === 'variant'

      return isExperimentVariantEnabled
    } catch (e) {
      return false
    }
  }

  return {
    getExperimentStatus,
  }
}

export default useExperiment
