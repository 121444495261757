import axios from 'axios'
import { v4 as uuid } from 'uuid'
import * as Sentry from '@sentry/nextjs'

import { EXPERIMENT_CONFIG } from 'configs/experiment'
import { getSession } from 'utils/auth'

import type { ExperimentResponse } from 'types/experiment'

/**
 * This is where you include all of your predefined parameters, eg. timestamp, etc.
 */
export const getExperimentDefaultData = (): {} => {
  return {}
}

/**
 * This is where you include all of user-related parameters, eg. user_id
 */
export const getExperimentUserData = (): { userId?: string } => {
  const session = getSession()

  return {
    userId: session?.user?.email,
  }
}

const logPrefix = '[A/B Test] Guru-FE Experiment Utils'

const log = (message: string, type = 'log') => {
  if (EXPERIMENT_CONFIG.debug) {
    if (type === 'error') console.error(logPrefix, message)
    // eslint-disable-next-line no-console
    else console.log(logPrefix, message)
  }
}

const logError = (message: string) => {
  // Only log errors if debug is enabled and not on production
  if (EXPERIMENT_CONFIG.debug) {
    // For non production environments, log the errors to the browser
    if (process.env.NODE_ENV !== 'production') {
      log(message, 'error')
    }

    // For production environment, send to sentry
    else {
      Sentry.captureMessage(`${logPrefix}: ${message}`, 'error')
    }
  }

  return null
}

export const mutateExperimentParticipation = async (
  experimentName: string,
  deviceId: string,
  data?: any
): Promise<ExperimentResponse> => {
  const params = {
    ...getExperimentDefaultData(),
    ...getExperimentUserData(),
    ...data,
    deviceId,
  }
  const defaultExperimentResponse = {
    experiment: {
      name: experimentName,
      treatment: 'control',
    },
  }

  try {
    const response = await axios.post(
      `${EXPERIMENT_CONFIG.baseUrl}/experiment/${experimentName}/participate`,
      params,
      {
        headers: {
          Authorization: `Basic ${EXPERIMENT_CONFIG.credentials}`,
          'X-Request-ID': uuid(),
        },
      }
    )
    return response?.data ?? defaultExperimentResponse
  } catch (e) {
    const message = e?.message ?? 'Unknown error'
    logError(message)
    return { ...defaultExperimentResponse, error: message }
  }
}
