export const CONVERSATION_ONBOARDING_IDEAS = [
  {
    text: 'Memberikan saran ide pengajaran',
    textVariant: 'body',
    icon: '/icon/menu-perangkat-ajar.svg',
  },
  {
    text: 'Saran kegiatan modul projek',
    textVariant: 'body',
    icon: '/icon/heart-empathic-give.svg',
  },
  {
    text: 'Saran tema untuk acara sekolah',
    textVariant: 'body',
    icon: '/icon/sharing-bukti-karya.svg',
  },
  {
    text: 'Masih banyak lagi!',
    textVariant: 'action',
    icon: '/icon/ide-praktik.svg',
  },
]

export const CONVERSATION_MESSAGE_SUGGESTIONS = [
  'Berikan alternatif ide pengajaran untuk mengenalkan bangun datar',
  'Ide kegiatan projek untuk dimensi berkebinekaan global di SMA',
]

export const GURU_AI_AVATAR_IMAGE_PATH = '/images/guru-ai-avatar.png'

// Message Feedback
export const COPY_TO_CLIPBOARD_TOAST_MESSAGE = 'Berhasil salin jawaban!'
export const LIKE_DISLIKE_CONTENTS = {
  like: {
    title: 'Terima kasih apresiasinya!',
    caption: 'Bagaimana jawaban Asisten Guru?',
    options: [
      'Sesuai kebutuhan',
      'Menginspirasi',
      'Cepat',
      'Mudah dipahami',
      'Lainnya',
    ],
    toastMessage: 'Terima kasih apresiasinya!',
  },
  dislike: {
    title: 'Maaf, ya... Apa yang kurang?',
    caption: 'Bantu Asisten Guru memperbaiki jawaban',
    options: [
      'Tidak sesuai kebutuhan',
      'Tidak inspiratif',
      'Tidak akurat',
      'Sulit dipahami',
      'Lainnya',
    ],
    toastMessage: 'Terima kasih untuk masukannya!',
  },
}

// Errors
export const NETWORK_ERROR_MESSAGE_RESPONSE_DATA = {
  name: 'network_error',
  message:
    'Tidak ada koneksi internet. Pastikan koneksi WiFi atau kuota internet Anda aktif, lalu coba lagi.',
}

export const INTERNAL_SERVER_ERROR_MESSAGE_RESPONSE_DATA = {
  name: 'internal_server_error',
  message: 'Terjadi kendala sistem. Silakan coba lagi dalam beberapa saat.',
}

export const ERROR_MESSAGES_TYPES = {
  INTERNAL_SERVER_ERROR: 'Internal server error',
  NETWORK_ERROR: 'Failed to fetch',
  TIMEOUT_ERROR: 'network error',
}

export const NO_ACCESS_ERROR_NAME = 'user_not_in_whitelist'

export const GREETING_MESSAGE = (
  <>
    Halo! Saya Aru, Asisten Guru.
    <br />
    <br />
    Saya adalah Kecerdasan Buatan. Saya bisa bantu jawab pertanyaan apapun
    tentang pendidikan.
    <br />
    <br />
    Tidak perlu takut salah. Aru akan jawab dengan senang hati 🙏🏼
    <br />
    <br />
    Mau tanya apa hari ini?
  </>
)
export const GREETING_MESSAGE_FOR_COPY =
  'Halo! Saya Aru, Asisten Guru. Saya adalah Kecerdasan Buatan. Saya bisa bantu jawab pertanyaan apapun tentang pendidikan. Tidak perlu takut salah. Aru akan jawab dengan senang hati 🙏🏼 Mau tanya apa hari ini?'
