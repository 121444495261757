import { useSatuEventTracker } from 'utils/satuEvent'
import AndroidHandler from 'utils/androidHandler'
import { useAuth } from 'utils/auth'
import { APP_DEVICE_ID_STORAGE_KEY } from 'configs/android'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import type { TSETrackConversationActivities } from 'types/conversation'

type TSETrackEventDefaultAttributes = {
  is_login: [boolean, 'bool']
  is_webview: [boolean, 'bool']
  email: string
}

const SATU_EVENT_NAME = 'guru_core_pwa_event_tracker_v1'
const TRACKER_EVENT_NAME = 'GURU_CORE_APP'
const DEFAULT_TIMEOUT = 5000 // 5 seconds
const DEFAULT_SCREEN_NAME = 'GURU_AI_PAGE'

export const useSETrackEvent = () => {
  const [, , , getAppDeviceIdValue] = useLocalStorage(
    APP_DEVICE_ID_STORAGE_KEY,
    null
  )

  const appDeviceID = getAppDeviceIdValue()
  const hasAppDeviceID = Boolean(appDeviceID)
  const androidHandler = AndroidHandler()
  const { session } = useAuth()
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()

  const SETrackEventDefaultAttributes: TSETrackEventDefaultAttributes = {
    is_login: [Boolean(session), 'bool'],
    is_webview: [androidHandler.enabled, 'bool'],
    email: session?.user?.email ?? null,
  }

  const SETrackComponent = (
    activity_type: TSETrackConversationActivities,
    additionalAttributes?: Record<string, any>
  ): void => {
    SETrackEvent({
      eventName: SATU_EVENT_NAME,
      eventParams: {
        activity_type,
        event_name: TRACKER_EVENT_NAME,
        user_id: session?.user?.id,
        ...(hasAppDeviceID ? { device_id: appDeviceID } : {}),
        attributes: SETransformAttrs({
          ...SETrackEventDefaultAttributes,
          ...additionalAttributes,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const SETrackConversationPage = (
    activityType: TSETrackConversationActivities,
    additionalAttributes: Record<string, any> = {}
  ): void => {
    SETrackComponent(activityType, {
      event_trigger: DEFAULT_SCREEN_NAME,
      screen_name: DEFAULT_SCREEN_NAME,
      ...additionalAttributes,
    })
  }

  return {
    SETrackConversationPage,
  }
}
