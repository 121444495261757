import create, { type GetState, type SetState } from 'zustand'
import {
  persist,
  subscribeWithSelector,
  type StoreApiWithPersist,
} from 'zustand/middleware'
import { share } from 'shared-zustand'
import { v4 as uuid } from 'uuid'

import {
  CONVERSATION_STORAGE_KEY,
  CONVERSATION_ONBOARDING_STORAGE_KEY,
  CONVERSATION_LANDING_ONBOARDING_STORAGE_KEY,
  CONVERSATION_ENTRY_POINT_STORAGE_KEY,
} from 'app/ConversationPage/configs'
import { IS_CONVERSATION_WITH_AI_NEW_LANDING_ENABLED } from 'configs/toggle'

import type {
  TConversationMessages,
  TConversationMessageErrorType,
} from 'types/conversation'

type TConversationEntryPointStoreState = {
  isRendered?: boolean
  entryPointText?: string
  entryPointVariant?: string
  setRendered: () => void
  setEntryPointText: (entryPointText: string) => void
  setEntryPointVariant: (entryPointVariant: string) => void
}
export const useConversationEntryPointStore =
  create<TConversationEntryPointStoreState>(
    persist(
      (set) => ({
        isRendered: false,
        entryPointText: 'Bingung mengajar? AI bisa cerahkan',
        entryPointVariant: 'control',
        setRendered: () =>
          set(() => ({
            isRendered: true,
          })),
        setEntryPointText: (entryPointText) =>
          set(() => ({
            entryPointText,
          })),
        setEntryPointVariant: (entryPointVariant) =>
          set(() => ({
            entryPointVariant,
          })),
      }),
      {
        name: CONVERSATION_ENTRY_POINT_STORAGE_KEY,
      }
    )
  )

type TConversationOnboardingStoreState = {
  isOnboardingFinished?: boolean
  isCoachMarkFinished?: boolean
  setOnboardingFinished: () => void
  setCoachMarkFinished: () => void
}
export const useConversationOnboardingStore =
  create<TConversationOnboardingStoreState>(
    persist(
      (set) => ({
        isOnboardingFinished: false,
        isCoachMarkFinished: false,
        setOnboardingFinished: () =>
          set(() => ({
            isOnboardingFinished: true,
          })),
        setCoachMarkFinished: () =>
          set(() => ({
            isCoachMarkFinished: true,
          })),
      }),
      {
        name: CONVERSATION_ONBOARDING_STORAGE_KEY,
      }
    )
  )

type TConversationLandingOnboardingStoreState = {
  isLandingOnboardingFinished?: boolean
  landingOnboardingStep?: number
  landingOnboardingVariant?: string
  setLandingOnboardingFinished: () => void
  setLandingOnboardingStep: (landingOnboardingStep: number) => void
  setLandingOnboardingVariant: (landingOnboardingVariant: string) => void
  isLandingOnboardingVariantABC: () => boolean
}
export const useConversationLandingOnboardingStore =
  create<TConversationLandingOnboardingStoreState>(
    persist(
      (set, get) => ({
        isLandingOnboardingFinished: false,
        landingOnboardingStep: 0,
        landingOnboardingVariant: undefined,
        setLandingOnboardingFinished: () =>
          set(() => ({
            isLandingOnboardingFinished: true,
          })),
        setLandingOnboardingStep: (landingOnboardingStep) =>
          set(() => ({
            landingOnboardingStep,
          })),
        setLandingOnboardingVariant: (landingOnboardingVariant) =>
          set(() => ({
            landingOnboardingVariant,
          })),
        isLandingOnboardingVariantABC: () => {
          return (
            IS_CONVERSATION_WITH_AI_NEW_LANDING_ENABLED &&
            ['variant_1', 'variant_2', 'variant_3'].includes(
              get().landingOnboardingVariant
            )
          )
        },
      }),
      {
        name: CONVERSATION_LANDING_ONBOARDING_STORAGE_KEY,
      }
    )
  )

type TConversationStoreState = {
  conversationId?: string | undefined
  savedMessages?: TConversationMessages
  hasConversationId: () => boolean
  resetConversationId: () => void
  generateConversationId: () => void
  setConversationId: (conversationId: string | undefined) => void
  setSavedMessages: (savedMessages: TConversationMessages) => void
  resetSavedMessages: () => void
}
export const useConversationStore = create<
  TConversationStoreState,
  SetState<TConversationStoreState>,
  GetState<TConversationStoreState>,
  StoreApiWithPersist<TConversationStoreState>
>(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        conversationId: undefined,
        savedMessages: {},
        hasConversationId: () => Boolean(get()?.conversationId),
        resetConversationId: () => {
          set(() => ({
            conversationId: undefined,
          }))
        },
        generateConversationId: () => {
          set(() => ({
            conversationId: uuid(),
          }))
        },
        setConversationId: (conversationId) => {
          set(() => ({
            conversationId,
          }))
        },
        setSavedMessages: (savedMessages) => {
          set(() => ({
            savedMessages,
          }))
        },
        resetSavedMessages: () => {
          set(() => ({
            savedMessages: {},
          }))
        },
      }),
      {
        name: CONVERSATION_STORAGE_KEY,
      }
    )
  )
)

type TConversationMessageStoreState = {
  isConnecting?: boolean
  isStreaming?: boolean
  isConfirmSheetOpen?: boolean
  lastError?: TConversationMessageErrorType | undefined
  messages?: TConversationMessages
  errorBoundary?: any
  hasMessages?: () => boolean
  setConnecting: (isConnecting: boolean) => void
  setLastError: (lastError: TConversationMessageErrorType | undefined) => void
  setStreaming: (isStreaming: boolean) => void
  setMessages: (message: TConversationMessages) => void
  setErrorBoundary: (errorBoundary: any) => void
  resetMessages: () => void
  removeLastMessage: () => void
  setConfirmSheetOpen: (isConfirmSheetOpen: boolean) => void
}
export const useConversationMessageStore =
  create<TConversationMessageStoreState>(
    subscribeWithSelector((set, get) => ({
      isConnecting: false,
      isStreaming: false,
      isConfirmSheetOpen: false,
      lastError: undefined,
      messages: {},
      errorBoundary: undefined,
      hasMessages: () => Object.keys(get()?.messages ?? {}).length > 0,
      setConnecting: (isConnecting) => {
        set(() => ({
          isConnecting,
        }))
      },
      setLastError: (lastError) => {
        set(() => ({
          lastError,
        }))
      },
      setStreaming: (isStreaming) => {
        set(() => ({
          isStreaming,
        }))
      },
      setMessages: (message) => {
        set(() => ({
          messages: {
            ...get()?.messages,
            ...message,
          },
        }))
      },
      setErrorBoundary: (errorBoundary) => {
        set(() => ({
          errorBoundary,
        }))
      },
      resetMessages: () => {
        set(() => ({
          messages: {},
        }))
      },
      removeLastMessage: () => {
        set(() => {
          const messages = get()?.messages ?? {}
          const lastMessage =
            messages[Object.keys(messages)[Object.keys(messages).length - 1]]
          let newData = { ...get()?.messages }
          delete newData[lastMessage?.messageId]

          return {
            messages: newData,
          }
        })
      },
      setConfirmSheetOpen: (isConfirmSheetOpen) => {
        set(() => ({
          isConfirmSheetOpen,
        }))
      },
    }))
  )

if ('BroadcastChannel' in globalThis /* || isSupported() */) {
  share('isConnecting', useConversationStore, {
    ref: 'shared-conversation-is-connecting',
  })
  share('isError', useConversationStore, {
    ref: 'shared-conversation-is-error',
  })
  share('isStreaming', useConversationStore, {
    ref: 'shared-conversation-is-streaming',
  })
  share('conversationId', useConversationStore, {
    ref: 'shared-conversation-id',
  })
  share('messages', useConversationMessageStore, {
    ref: 'shared-conversation-messages',
  })
}
