import Head from 'next/head'

import { Text, type TextProps } from '@wartek-id/text-v1'

import type { WalktourLogic } from 'walktour'

type TooltipProps = WalktourLogic & {
  disablePrev: boolean
  prevLabel?: string
  nextLabel?: string
  closeLabel?: string
}

export function Tooltip({ disablePrev, ...props }: TooltipProps) {
  const {
    allSteps,
    stepIndex,
    prev,
    next,
    close,
    stepContent: {
      customDescriptionRenderer,
      customTitleRenderer,
      title,
      description,
    },
    prevLabel,
    nextLabel,
    closeLabel,
  } = props

  const isLastStep = stepIndex === allSteps.length - 1

  return (
    <>
      <Head>
        {/* Inject style to remove outline */}
        <style>
          {`#walktour-tooltip-container:focus-visible {
            outline: none !important;
          }`}
        </style>
      </Head>
      <div className="pl-8 text-blue-50">
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
        >
          <path
            d="M7.57796 1.74961C8.35974 0.485803 10.1979 0.485803 10.9797 1.74961L18.5577 14H0L7.57796 1.74961Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <div className="relative inset-0 w-screen max-w-xl mx-auto">
        <div className="max-w-xl px-3 py-4 mx-auto bg-blue-50">
          {customTitleRenderer
            ? customTitleRenderer(title, props)
            : title && (
                <Text variant="heading-sm" color="inverse" className="mb-1">
                  {title}
                </Text>
              )}
          {customDescriptionRenderer ? (
            customDescriptionRenderer(description, props)
          ) : (
            <Text variant="body" color="inverse">
              {description}
            </Text>
          )}

          <div className="flex justify-end mt-4 space-x-4">
            {!disablePrev && (
              <TooltipButton onClick={() => prev()}>
                {prevLabel ?? 'Kembali'}
              </TooltipButton>
            )}

            {isLastStep ? (
              <TooltipButton
                onClick={() => {
                  close()
                }}
              >
                {closeLabel ?? 'Selesai'}
              </TooltipButton>
            ) : (
              <TooltipButton onClick={() => next()}>
                {nextLabel ?? 'Lanjut'}
              </TooltipButton>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function TooltipButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & TextProps) {
  return (
    <Text
      as="button"
      type="button"
      variant="body-sm-bold"
      color="inverse"
      {...props}
    >
      {children}
    </Text>
  )
}
